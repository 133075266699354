import { Grid, Image } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import Background from "../../assets/background-blur.svg";
import BackgroundLine from "../../assets/background-line.svg";
import BackgroundPattern from "../../assets/bg-pattern.png";
import LoginImage from "../../assets/login-svg.webp";
import Layout from "../../components/layout/layout";
import LoginForm from "../../components/loginForm";
import { useScreenDetector } from "../../components/screen-detector";

const LoginPage = () => {
  const { classes } = useStyles();
  const { isTablet } = useScreenDetector();

  return (
    <Layout>
      <Image className={classes.background} src={Background} draggable={false} />
      <Image className={classes.backgroundLine} src={BackgroundLine} draggable={false} />
      <Grid className={classes.container} align="center">
        {!isTablet && (
          <Grid.Col span={{ sm: 12, md: 4, lg: 6 }} className={classes.image}>
            <img src={LoginImage} alt={"svg"} style={{ height: "100%", width: "100%", objectFit: "contain" }} draggable={false} />
          </Grid.Col>
        )}
        <Grid.Col span={{ sm: 12, md: 8, lg: 8, xl: 4 }} className={classes.card}>
          <img className={classes.bgBlur} src={BackgroundPattern} alt="" draggable={false} />
          <div className={classes.content}>
            <div>
              <div className={classes.loginText}>НЭВТРЭХ</div>
              <LoginForm />
            </div>
            <div className={classes.info}>Баталгаажуулалт хийх заавартай танилцах</div>
          </div>
        </Grid.Col>
      </Grid>
    </Layout>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    height: "100%",
    maxWidth: "1600px",
    margin: "auto",
    paddingTop: 150,
  },
  card: {
    position: "relative",
    width: "100%",
    maxWidth: "600px !important",
    minWidth: "320px !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ffffff09",
    flexWrap: "wrap",
    margin: "auto",
  },
  image: {
    height: "800px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    zIndex: 1,
    display: "flex",
    paddingInline: 40,
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    width: "100%",
    paddingTop: 35,
    paddingBottom: 35,
  },
  bgBlur: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  text: {
    color: theme.white,
    fontSize: 16,
    fontWeight: 400,
  },
  loginText: {
    marginTop: 15,
    fontSize: 40,
    color: theme.white,
    fontWeight: 800,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  background: {
    position: "absolute",
    opacity: 0.5,
    bottom: 0,
  },
  backgroundLine: {
    position: "absolute",
    opacity: 0.1,
    bottom: 0,
  },
  info: {
    flex: 1,
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
    color: "white",
  },
}));

export default LoginPage;
