import { Box, Image, LoadingOverlay, MantineTheme } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React from "react";
import Background from "../../assets/background-blur.svg";
import BackgroundLine from "../../assets/background-line.svg";
import LoginImage from "../../assets/login-svg.webp";
import ForgotForm from "../../components/forgotForm";
import Layout from "../../components/layout/layout";

const ForgotPassword = () => {
  const { classes } = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <Layout>
      <Image className={classes.background} src={Background} draggable={false} />
      <Image className={classes.backgroundLine} src={BackgroundLine} draggable={false} />
      <Box className={classes.container}>
        <Image src={LoginImage} alt={"svg"} className={classes.image} draggable={false} />
        <Box className={classes.cardWrapper}>
          <Box className={classes.card}>
            <LoadingOverlay visible={loading} />
            <h1 className={classes.loginText}>НУУЦ ҮГ СЭРГЭЭХ</h1>
            <ForgotForm setLoading={setLoading} />
            <Box className={classes.info}>Баталгаажуулалт хийх заавартай танилцах</Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

const useStyles = createStyles((theme: MantineTheme) => ({
  container: {
    display: "flex",
    maxWidth: "80%",
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing.xl,
    height: "100%",
    [`@media (max-width: 576px)`]: {
      maxWidth: "100%",
      paddingInline: "2rem",
    },
    [`@media (min-width: 577px) and (max-width: 1024px)`]: {
      maxWidth: "100%",
      paddingInline: "2rem",
    },
    [`@media (min-width: 1025px) and (max-width: 1150px)`]: {
      maxWidth: "96%",
    },
  },
  cardWrapper: {},
  card: {
    backgroundColor: "#ffffff09",
    padding: theme.spacing.xl,
  },
  image: {
    width: 700,
    height: "auto",
    [`@media (max-width: 576px)`]: {
      display: "none",
    },
    [`@media (min-width: 577px) and (max-width: 1024px)`]: {
      display: "none",
    },
    [`@media (min-width: 1025px) and (max-width: 1366px)`]: {
      width: "50%",
      height: "auto",
    },
    [`@media (min-width: 1367px) and (max-width: 1440px)`]: {
      width: "60%",
      height: "auto",
    },
  },
  loginText: {
    marginTop: 15,
    fontSize: 40,
    color: theme.white,
    fontWeight: 800,
    [`@media (max-width: 576px)`]: {
      fontSize: 30,
    },
    [`@media (min-width: 577px) and (max-width: 1024px)`]: {
      fontSize: 32,
    },
    [`@media (min-width: 1025px) and (max-width: 1366px)`]: {
      fontSize: 34,
    },
    [`@media (min-width: 1367px) and (max-width: 1440px)`]: {
      fontSize: 36,
    },
  },
  background: {
    position: "absolute",
    opacity: 0.5,
    bottom: 0,
  },
  backgroundLine: {
    position: "absolute",
    opacity: 0.1,
    bottom: 0,
  },
  info: {
    color: "#fff",
    textAlign: "center",
    marginTop: theme.spacing.lg,
  },
}));

export default ForgotPassword;
